<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Penerapan Perizinan Berusaha</div>
                  <div class="wizard-desc">Penerapan Perizinan Berusaha</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Penyederhanaan Perizinan Berusaha Sektor
                  </div>
                  <div class="wizard-desc">
                    Penyederhanaan Perizinan Berusaha Sektor
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Peningkatan Iklim Investasi</div>
                  <div class="wizard-desc">Peningkatan Iklim Investasi</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div
              class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10"
            >
              <div class="col-xl-12 col-xxl-10">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div v-if="pm.catatan_1_surveyor != null">
                        <b-alert show variant="danger"
                          >Terdapat catatan, silahkan lihat catatan
                          dibawah!</b-alert
                        >
                      </div>
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penerapan Perizinan
                          Berusaha berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Penyusunan dan atau revisi peraturan daerah
                                dan/atau peraturan kepala daerah</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Untuk Kementerian Negara/ Lembaga (K/L) yang telah
                              memiliki NSPK, yang dimaksud dalam tolok ukur di
                              sini adalah konsultasi dalam rangka evaluasi atas
                              NSPK yang sudah ada. Termasuk jika ada KBLI yang
                              belum diatur/diampu di dalam PP No. 5 Tahun 2021.
                              Penilaian konsultasi pengusulan NSPK dengan
                              Kementerian terkait lainnya dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file1)"
                                v-if="pm.p1a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file2)"
                                v-if="pm.p1a1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file3)"
                                v-if="pm.p1a1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file4)"
                                v-if="pm.p1a1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file5)"
                                v-if="pm.p1a1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada harmonisasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Pilihan pada indikator dan tolok ukur ini
                              menyesuaikan dengan Pilihan pada indikator
                              Penyusunan NSPK terkait penerapan perizinan
                              berusaha. Penilaian harmonisasi pengusulan NSPK
                              dengan Kementerian terkait lainnya
                              (sekurang-kurangnya melibatkan Kementerian
                              Koordinator Bidang Perekonomian, Sekretariat
                              Kabinet, atau Kementerian Investasi/BKPM)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file1)"
                                v-if="pm.p1a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file2)"
                                v-if="pm.p1a2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file3)"
                                v-if="pm.p1a2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file4)"
                                v-if="pm.p1a2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file5)"
                                v-if="pm.p1a2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">3</td>
                            <td rowspan="3">
                              Apakah ada dokumen NSPK terkait penerapan
                              Perizinan Berusaha?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1a3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1a3_nilai"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1a3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1a3_nilai"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1a3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1a3_nilai"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file1)"
                                v-if="pm.p1a3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr colspan="2">
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file2)"
                                v-if="pm.p1a3_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya dokumen NSPK terkait
                                    penerapan Perizinan berusaha dilakukan
                                    dengan ketentuan tersedia dokumen NSPK
                                    terkait penerapan Perizinan berusaha dalam
                                    lembaran negara. Untuk penetapan bobot
                                    penilaian: <br />
                                    1. Nilai “Ada, sudah disahkan” apabila
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    ketentuan di atas tidak terpenuhi namun
                                    sudah tersedia dalam bentuk naskah/dokumen
                                    usulan. <br />
                                    3. Nilai “Belum ada” apabila ketentuan di
                                    atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi NSPK Penerapan
                              Perizinan Berusaha?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian ada tidaknya Sosialisasi NSPK Penerapan
                              Perizinan Berusaha dilakukan dengan ketentuan:
                              sosialisasi dilakukan sekurang-kurangnya 1 kali
                              dalam 3 bulan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan secara Nasional
                                  sekurang-kurangnya dilakukan di tingkat
                                  Provinsi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file1)"
                                v-if="pm.p1a4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file2)"
                                v-if="pm.p1a4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file3)"
                                v-if="pm.p1a4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file4)"
                                v-if="pm.p1a4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1a4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file5)"
                                v-if="pm.p1a4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Integrasi sistem Kementrian Negara/Lembaga
                                dengan sistem OSS</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah memiliki sistem di K/L yang terintegrasi
                              dengan OSS atau menggunakan sistem OSS dengan hak
                              akses?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian integrasi sistem atau penggunaan hak
                              akses OSS oleh kementerian negara/lembaga
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Semua pemrosesan permohonan perizinan berusaha
                                  sektor berdasarkan lampiran PP No. 5 Tahun
                                  2021 yang menjadi kewenangannya menggunakan
                                  sistem K/L yang terintegrasi dengan OSS
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b1_file1)"
                                v-if="pm.p1b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Semua pemrosesan permohonan perizinan berusaha
                                  sektor berdasarkan lampiran PP No. 5 Tahun
                                  2021 yang menjadi kewenangannya menggunakan
                                  sistem OSS dengan hak akses
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b1_file2)"
                                v-if="pm.p1b1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Memiliki sistem dan Terintegrasi”
                                    apabila ketentuan nomor 1 terpenuhi.<br />
                                    2. Nilai “Tidak memiliki sistem dan
                                    menggunakan hak akses” apabila ketentuan
                                    nomor 2 terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">
                              Berapa persen tingkat verifikasi teknis dan
                              notifikasi pemenuhan standar dan persyaratan
                              Perizinan Berusaha Berbasis Risiko?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1b2_nilai"
                                  value="25"
                                  >85 – 100%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1b2_nilai"
                                  value="15"
                                  >70 – 84,99%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1b2_nilai"
                                  value="10"
                                  >50 – 69,99%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1b2_nilai"
                                  value="0"
                                  >di bawah 50%</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b2_file1)"
                                v-if="pm.p1b2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b2_file2)"
                                v-if="pm.p1b2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian verifikasi teknis dan notifikasi
                                    pemenuhan standar dan persyaratan Perizinan
                                    Berusaha Berbasis Risiko dilakukan dengan
                                    ketentuan: <br />
                                    1. Verifikasi teknis dan notifikasi
                                    pemenuhan standar oleh K/L terhadap
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko Menengah Tinggi (MT) yang menjadi
                                    kewenangannya.<br />
                                    2. Verifikasi teknis dan notifikasi
                                    pemenuhan persyaratan oleh K/L terhadap
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko Tinggi (T) yang menjadi
                                    kewenangannya. <br />
                                    3. Penilaian verifikasi teknis dan
                                    notifikasi pemenuhan standar dan persyaratan
                                    Perizinan Berusaha Berbasis Risiko dilakukan
                                    dengan menghitung perbandingan jumlah
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko MT dan T yang telah diverifikasi
                                    dengan total permohonan perizinan berusaha
                                    yang masuk ke dalam dashboard OSS K/L
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi
                              implementasi Perizinan Berusaha?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi implementasi Perizinan Berusaha dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 semester
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file1)"
                                v-if="pm.p1c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file2)"
                                v-if="pm.p1c1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file3)"
                                v-if="pm.p1c1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula Monev</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file4)"
                                v-if="pm.p1c1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file5)"
                                v-if="pm.p1c1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan kinerja implementasi Perizinan
                              Berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan kinerja
                                  implementasi perizinan berusaha dilakukan
                                  dengan ketentuan apabila laporan kinerja
                                  implementasi Perizinan Berusaha dibuat secara
                                  formal dalam bentuk dokumen laporan lengkap
                                  fisik atau digital, bukan bahan presentasi dan
                                  sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c2_file1)"
                                v-if="pm.p1c2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_1_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_1_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div v-if="pm.catatan_2_surveyor != null">
                        <b-alert show variant="danger"
                          >Terdapat catatan, silahkan lihat catatan
                          dibawah!</b-alert
                        >
                      </div>
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penyederhanaan
                          Perizinan Berusaha Sektor berdasarkan fakta yang ada
                          dengan memilih data yang sesuai. Dokumen lampiran yang
                          diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Penapisan bidang usaha berdasarkan tingkat
                                risiko</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Apakah ada kegiatan penapisan bidang usaha
                              berdasarkan risiko?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2a1_nilai"
                                  value="25"
                                  >Ada, semua</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2a1_nilai"
                                  value="10"
                                  >Ada, Sebagian</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2a1_nilai"
                                  value="0"
                                  >Tidak ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a1_file1)"
                                v-if="pm.p2a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a1_file2)"
                                v-if="pm.p2a1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penilaian ada tidaknya kegiatan penapisan
                                      bidang usaha berdasarkan risiko dilakukan
                                      dengan ketentuan
                                    </div>
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      K/L melakukan penapisan pada semua KBLI
                                      yang disebutkan dalam PP No. 5 Tahun 2021
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      K/L melakukan penapisan KBLI sesuai surat
                                      Sekretaris Menteri Koordinator Bidang
                                      Perekonomian Nomor No.
                                      PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                      Juni 2021 perihal KBLI Beririsan dan KBLI
                                      Tanpa K/L Pengampu
                                    </div>
                                    <div class="col-1">3.</div>
                                    <div class="col-11">
                                      K/L yang tidak memiliki kewajiban
                                      melakukan penapisan pada KLBI sesuai surat
                                      Sekretaris Menteri Koordinator Bidang
                                      Perekonomian Nomor No.
                                      PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                      Juni 2021 perihal KBLI Beririsan dan KBLI
                                      Tanpa K/L Pengampu
                                    </div>
                                    <div class="col-12">
                                      Nilai “Ada, semua” apabila
                                    </div>
                                    <div class="col-1">a.</div>
                                    <div class="col-11">
                                      Ketentuan nomor 1 dan 2 terpenuhi
                                    </div>
                                    <div class="col-1">b.</div>
                                    <div class="col-11">
                                      Ketentuan nomor 1 dan 3 terpenuhi.
                                    </div>
                                    <div class="col-12">
                                      Nilai “Ada, sebagian” apabila
                                    </div>
                                    <div class="col-1">a.</div>
                                    <div class="col-11">
                                      K/L melakukan penapisan pada semua KBLI
                                      yang disebutkan dalam PP No. 5 Tahun 2021.
                                      Namun, tidak melakukan penapisan terhadap
                                      semua KBLI sesuai surat Sekretaris Menteri
                                      Koordinator Bidang Perekonomian Nomor No.
                                      PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                      Juni 2021 perihal KBLI Beririsan dan KBLI
                                      Tanpa K/L Pengampu, maupun sebaliknya
                                    </div>
                                    <div class="col-1">b.</div>
                                    <div class="col-11">
                                      K/L melakukan penapisan pada sebagian KBLI
                                      yang disebutkan dalam PP No. 5 Tahun 2021.
                                      Namun, telah melakukan penapisan pada
                                      semua KBLI sesuai surat Sekretaris Menteri
                                      Koordinator Bidang Perekonomian Nomor No.
                                      PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                      Juni 2021 perihal KBLI Beririsan dan KBLI
                                      Tanpa K/L, maupun sebaliknya
                                    </div>
                                    <div class="col-1">c.</div>
                                    <div class="col-11">
                                      K/L melakukan penapisan pada sebagian baik
                                      atas KBLI yang disebutkan dalam PP No. 5
                                      Tahun 2021 maupun pada KBLI sesuai surat
                                      Sekretaris Menteri Koordinator Bidang
                                      Perekonomian Nomor No.
                                      PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                      Juni 2021 perihal KBLI Beririsan dan KBLI
                                      Tanpa K/L
                                    </div>
                                    <div class="col-12">
                                      Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi
                                    </div>
                                  </div>
                                  <!-- <p style="color:white;">Untuk penetapan bobot penilaian: <br>
                                                        1. Untuk pemerintah provinsi, nilai “Ada” apabila dapat menunjukkan naskah/dokumen usulan Perda mengenai Rencana Tata Ruang Wilayah (RTRW) dalam dokumen Propemperda.<br>
                                                        2. Untuk pemerintah kabupaten/kota, nilai “Ada” apabila dapat menunjukkan naskah/dokumen usulan Perkada mengenai Rencana Detail Tata Ruang (RDTR) dalam dokumen Propemperkada. <br>
                                                        3. Nilai “Tidak ada” apabila pemerintah provinsi tidak dapat memenuhi ketentuan nomor 1 dan pemerintah kabupaten/kota tidak dapat memenuhi ketentuan nomor 1 dan 2.
                                                    </p> -->
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">2</td>
                            <td rowspan="4">
                              Apakah ada hasil penapisan bidang usaha
                              berdasarkan risiko?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian ada tidaknya hasil penapisan bidang
                              usaha berdasarkan risiko dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  tolok ukur ini sesuai dengan penilaian pada
                                  tolok ukur menilai ada tidaknya kegiatan
                                  penapisan bidang usaha berdasarkan risiko dan
                                  wajib menunjukkan dokumen hasil penapisan pada
                                  seluruh KBLI yang menjadi kewenanganny
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file1)"
                                v-if="pm.p2a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  K/L yang tidak memiliki kewajiban melakukan
                                  penapisan pada KBLI sesuai surat Sekretaris
                                  Menteri Koordinator Bidang Perekonomian Nomor
                                  No. PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                  Juni 2021 perihal KBLI Beririsan dan KBLI
                                  Tanpa K/L Pengampu
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file2)"
                                v-if="pm.p2a2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, semua” apabila ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Ada, sebagian” apabila salah satu
                                    ketentuan di atas tidak terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Penyusunan NSPK berdasarkan penapisan bidang
                                usaha</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada konsultasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Untuk K/L yang telah memiliki NSPK, yang dimaksud
                              dalam tolok ukur di sini adalah konsultasi dalam
                              rangka evaluasi atas NSPK yang sudah ada. Termasuk
                              penapisan pada KBLI sesuai surat Sekretaris
                              Menteri Koordinator Bidang Perekonomian Nomor No.
                              PI.01./433/SES.M.EKON/06/2021 tanggal 16 Juni 2021
                              perihal KBLI Beririsan dan KBLI Tanpa K/L
                              Pengampu. Penilaian konsultasi pengusulan NSPK
                              dengan Kementerian terkait lainnya
                              (sekurang-kurangnya melibatkan Kementerian
                              Koordinator Bidang Perekonomian, Sekretariat
                              Kabinet, atau Kementerian Investasi/BKPM)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 6 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b1_file1)"
                                v-if="pm.p2b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b1_file2)"
                                v-if="pm.p2b1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b1_file3)"
                                v-if="pm.p2b1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b1_file4)"
                                v-if="pm.p2b1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b1_file5)"
                                v-if="pm.p2b1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada harmonisasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Untuk K/L yang telah memiliki NSPK, yang dimaksud
                              dalam tolok ukur di sini adalah harmonisasi dalam
                              rangka Evaluasi atas NSPK yang sudah ada. Termasuk
                              penapisan pada KBLI sesuai surat Sekretaris
                              Menteri Koordinator Bidang Perekonomian Nomor No.
                              PI.01./433/SES.M.EKON/06/2021 tanggal 16 Juni 2021
                              perihal KBLI Beririsan dan KBLI Tanpa K/L
                              Pengampu. Penilaian harmonisasi pengusulan NSPK
                              dengan Kementerian terkait lainnya
                              (sekurang-kurangnya melibatkan Kementerian
                              Koordinator Bidang Perekonomian, Sekretariat
                              Kabinet, atau Kementerian Investasi/BKPM)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file1)"
                                v-if="pm.p2b2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file2)"
                                v-if="pm.p2b2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file3)"
                                v-if="pm.p2b2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file4)"
                                v-if="pm.p2b2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file5)"
                                v-if="pm.p2b2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">3</td>
                            <td rowspan="3">
                              Apakah ada NSPK berdasarkan penapisan bidang
                              usaha?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2b3_nilai"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2b3_nilai"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2b3_nilai"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file1)"
                                v-if="pm.p2b3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr colspan="2">
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file2)"
                                v-if="pm.p2b3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya dokumen NSPK
                                    berdasarkan penapisan bidang usaha dilakukan
                                    dengan ketentuan tersedia dokumen NSPK
                                    berdasarkan penapisan bidang usaha dalam
                                    lembaran negara
                                  </p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, sudah disahkan” apabila salah
                                    satu ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    seluruh ketentuan di atas tidak terpenuhi
                                    namun sudah tersedia dalam bentuk
                                    naskah/dokumen usulan. <br />
                                    3. Nilai “Belum ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="8">4</td>
                            <td rowspan="8">
                              Apakah ada kegiatan sosialisasi NSPK penapisan
                              bidang usaha berdasarkan tingkat risiko?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian ada tidaknya kegiatan sosialisasi NSPK
                              penapisan bidang usaha berdasarkan tingkat risiko
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 1 semester
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file1)"
                                v-if="pm.p2b4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan secara Nasional
                                  sekurang-kurangnya dilakukan di tingkat
                                  Provinsi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file2)"
                                v-if="pm.p2b4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file3)"
                                v-if="pm.p2b4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file4)"
                                v-if="pm.p2b4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file5)"
                                v-if="pm.p2b4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2b4_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file6)"
                                v-if="pm.p2b4_file6 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, hanya 1 kali” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada monitoring dan evaluasi implementasi
                              penapisan bidang usaha berdasarkan tingkat risiko?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian ada tidaknya monitoring dan evaluasi
                              implementasi penapisan bidang usaha berdasarkan
                              tingkat risiko dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 semester
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c1_file1)"
                                v-if="pm.p2c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c1_file2)"
                                v-if="pm.p2c1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c1_file3)"
                                v-if="pm.p2c1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c1_file4)"
                                v-if="pm.p2c1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p2c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c1_file5)"
                                v-if="pm.p2c1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>

                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_2_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_2_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div v-if="pm.catatan_3_surveyor != null">
                        <b-alert show variant="danger"
                          >Terdapat catatan, silahkan lihat catatan
                          dibawah!</b-alert
                        >
                      </div>
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Peningkatan Iklim
                          Investasi berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Unit Pelaksana PPB</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada Organisasi Pelaksana PPB?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya Organisasi Pelaksana PPB
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki dan dapat menunjukkan penetapan/
                                  keputusan dari Menteri/Kepala Lembaga
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file1)"
                                v-if="pm.p3a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki ruang sekretariat organisasi tim
                                  internal K/L yang melaksanakan tugas dan
                                  fungsi PPB
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file2)"
                                v-if="pm.p3a1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">2</td>
                            <td rowspan="4">Apakah ada program kerja PPB?</td>
                            <td colspan="2">
                              Penilaian ada tidaknya program kerja PPB dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki dan dapat menunjukkan bukti
                                  program kerja
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file1)"
                                v-if="pm.p3a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki dan dapat menunjukkan bukti
                                  realisasi program kerja (sekurang-kurangnya
                                  50% dari program kerja yang direncanakan)
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file2)"
                                v-if="pm.p3a2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada rapat koordinasi unit pelaksana PPB?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya rapat koordinasi teragenda
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 1 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file1)"
                                v-if="pm.p3a3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file2)"
                                v-if="pm.p3a3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file3)"
                                v-if="pm.p3a3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file4)"
                                v-if="pm.p3a3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file5)"
                                v-if="pm.p3a3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Koordinasi Formal Lintas Pemangku
                                kepentingan</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan sesama
                              instansi Pusat?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya koordinasi formal dengan
                              sesama instansi Pusat dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 6 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file1)"
                                v-if="pm.p3b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file2)"
                                v-if="pm.p3b1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file3)"
                                v-if="pm.p3b1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file4)"
                                v-if="pm.p3b1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file5)"
                                v-if="pm.p3b1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pemerintah
                              Provinsi?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Provinsi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file1)"
                                v-if="pm.p3b2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file2)"
                                v-if="pm.p3b2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file3)"
                                v-if="pm.p3b2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file4)"
                                v-if="pm.p3b2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file5)"
                                v-if="pm.p3b2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pemerintah
                              Kab/Kota?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Pemda Kabupaten/Kota dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file1)"
                                v-if="pm.p3b3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file2)"
                                v-if="pm.p3b3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file3)"
                                v-if="pm.p3b3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file4)"
                                v-if="pm.p3b3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file5)"
                                v-if="pm.p3b3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pemangku kepentingan lainnya
                              (Perbankan dan APH)?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya koordinasi pemangku
                              kepentingan lainnya (Perbankan dan APH) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file1)"
                                v-if="pm.p3b4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file2)"
                                v-if="pm.p3b4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file3)"
                                v-if="pm.p3b4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file4)"
                                v-if="pm.p3b4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file5)"
                                v-if="pm.p3b4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi hasil
                              peningkatan iklim investasi?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi hasil peningkatan iklim investasi
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file1)"
                                v-if="pm.p3c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file1)"
                                v-if="pm.p3c1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file3)"
                                v-if="pm.p3c1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file4)"
                                v-if="pm.p3c1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file5)"
                                v-if="pm.p3c1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan hasil monitoring peningkatan
                              iklim investasi?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan hasil
                                  monitoring peningkatan iklim investasi
                                  dilakukan dengan ketentuan apabila laporan
                                  hasil monitoring peningkatan iklim investasi
                                  dibuat secara formal dalam bentuk dokumen
                                  laporan lengkap fisik atau digital, bukan
                                  bahan presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c2_file1)"
                                v-if="pm.p3c2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Tindak lanjut atas hasil Evaluasi</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              AApakah ada evaluasi hasil kinerja PPB tahun
                              sebelumnya?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  <div class="row">
                                    <div class="col-12">
                                      Apakah ada evaluasi hasil kinerja PPB
                                      tahun sebelumnya?
                                    </div>
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      Terdapat surat undangan resmi (melibatkan
                                      pihak eksternal) dalam bentuk fisik atau
                                      digital
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      Terdapat daftar hadir peserta rapat dalam
                                      bentuk fisik atau digital
                                    </div>
                                    <div class="col-1">3.</div>
                                    <div class="col-11">
                                      Terdapat notula rapat
                                    </div>
                                    <div class="col-1">4.</div>
                                    <div class="col-11">
                                      Terdapat dokumentasi rapat dalam bentuk
                                      foto
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d1_file1)"
                                v-if="pm.p3d1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada penambahan alokasi anggaran (rutin)
                              pelayanan perizinan berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya penambahan alokasi
                                  anggaran (rutin) pelayanan perizinan berusaha
                                  dilakukan dengan ketentuan apabila ada
                                  penambahan alokasi anggaran (rutin) pelayanan
                                  perizinan berusaha.
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d2_file1)"
                                v-if="pm.p3d2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_3_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_3_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Sebelumnya
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Selesai
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Selanjutnya
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>
  
  <style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-7.scss";
</style>
  
  
  <script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "Wizard-4",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Detil Pengisian" }]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.loadData();
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      id_ptsp: localStorage.getItem("idptsp"),
      tahun: new Date().getFullYear(),
      user: JSON.parse(localStorage.getItem("user")),
      vf: [],
      pm: [],
      id_verifikasi: null,
      profil: [],
    };
  },

  methods: {
    submit: function (e) {
      e.preventDefault();

      this.$router.push({ name: "dashboard" });
    },
    unduhFile(data) {
      window.open(data, "_blank");
    },
    loadData() {
      axios
        .get(
          this.url +
            `/pm_ppb_kl_2023s?filter=idkl,=,` +
            this.$route.params.id +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.pm = response.data.data;
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>